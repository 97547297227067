import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import emailjs from 'emailjs-com';

function Appointment({ show, handleClose, doctor }) {

    const doctors = [
        { id: 11, name: 'Dr. Najwa Ismail', image: '/doctors/najwa.JPG', speciality: 'Physiotherapist', languages: ['Arabic', 'English'] },
        { id: 9, name: 'Dr. Hala Mousa', image: '/doctors/hala.JPG', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
        { id: 7, name: 'Dr. Abeer Yousef', image: '/doctors/abeer.JPG', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
        { id: 1, name: 'Dr. Muhammad Rami Arjah', image: '/doctors/user.png', speciality: 'Consultant Child & Adolescent Psychiatry', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Iman Mahasneh', image: '/doctors/user.png', speciality: 'Speech Therapist', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Mohammed Al Smadi', image: '/doctors/user.png', speciality: 'Speech Therapist', languages: ['Arabic', 'English'] },
        { id: 4, name: 'Dr. Kunnampallil Gego', image: '/doctors/user.png', speciality: 'Speech Therapist', languages: ['English', 'Arabic'] },
        { id: 5, name: 'Dr. Lana Al Natour', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['English', 'Arabic'] },
        { id: 6, name: 'Dr. Adnan Aburayya', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['English', 'Arabic'] },
        { id: 8, name: 'Dr. Raed Alklakil', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Dr. Hiba Mohammad', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
    ];

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const specialities = Array.from(new Set(doctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = doctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language)));
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };

    function sendEmail(e) {
        console.log("gg")
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fwgrqew', 'template_4v62mp8', e.target, 'tKbfD7Qc7HH_s5Z5R')
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });

    }
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header>
                <button style={{ backgroundColor: "#8f4198", borderColor: "#8f4198", color: "white" }} onClick={() => handleClose()}>
                    {/* <FaX /> */}
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="bg-light rounded h-100 d-flex align-items-center p-5">
                        <form onSubmit={sendEmail}>
                            <div className="row g-3">
                                <div className="col-12 col-sm-6">
                                    <input name="name" type="text" className="form-control border-0" placeholder="Your Name" style={{ height: '55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input type="email" name="email" className="form-control border-0" placeholder="Your Email" style={{ height: '55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input type="text" name="phone" className="form-control border-0" placeholder="Your Mobile" style={{ height: '55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <select name="service" className="form-select border-0" style={{ height: '55px' }} onChange={handleSpecialityChange}>
                                        <option selected>Choose speciality</option>
                                        {specialities.map((s) => (
                                            <option selected>{s}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <select name="doctor" className="form-select border-0" style={{ height: '55px' }}>
                                        <option selected>Choose Doctor</option>
                                        {filteredDoctors.map((doc, index) => (
                                            <option key={index} value={doc.name}>
                                                Doctor: {doc.name}
                                            </option>
                                        ))}


                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input
                                        type="datetime-local"
                                        className="form-control border-0 datetimepicker-input"
                                        placeholder="Choose Date"
                                        style={{ height: '55px' }}
                                        name="date"
                                    />

                                </div>
                                <div>
                                    <input type="hidden" placeholder="Email" name="sendTarget" value="Info@wLdy.Ae" />
                                </div>

                                <div className="col-12">
                                    <textarea name="message" className="form-control border-0" rows="5" placeholder="Describe your problem"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">
                                        Book Appointment
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body >

        </Modal >
    )

}
export default Appointment