import Home from "../Home/Home"
import '../../App.css';
import React from 'react';

function Slider() {
    React.useEffect(() => {
        const owlCarousel = document.querySelector('.header-carousel');
        if (owlCarousel) {
            window.$(owlCarousel).owlCarousel({
                loop: true,
                items: 1,
                autoplay: true,
                autoplayTimeout: 5000,
                animateIn: 'fadeIn',
                animateOut: 'fadeOut',
                smartSpeed: 1500,
            });
        }
    }, []);
    return (
        <div className="container-fluid header bg-primary p-0 mb-5">
            <div className="row g-0 align-items-center flex-column-reverse flex-lg-row" style={{ backgroundColor: "#2e7ebe" }}>
                <div className="col-lg-6 p-5 wow fadeIn" data-wow-delay="0.1s" >
                    <h1 className="display-4 text-white mb-5" style={{ color: "#dc0976" }}>Specialties Tailored for Children’s Needs</h1>
                    <h2 className="text-white" style={{ color: "#dc0976", fontWeight: "lighter", fontSize: "25px" }}>Wldy Medical Center represents a beacon of hope for families seeking comprehensive pediatric rehabilitation services in Al Ain. Our specialized facility is designed to support your child's unique journey towards optimal health and development.</h2>
                    {/* <div className="row g-4">
                        <div className="col-sm-4">
                            <div className="border-start border-light ps-4">
                                <h2 className="text-white mb-1" data-toggle="counter-up">123</h2>
                                <p className="text-light mb-0">Expert Doctors</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="border-start border-light ps-4">
                                <h2 className="text-white mb-1" data-toggle="counter-up">1234</h2>
                                <p className="text-light mb-0">Medical Staff</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="border-start border-light ps-4">
                                <h2 className="text-white mb-1" data-toggle="counter-up">12345</h2>
                                <p className="text-light mb-0">Total Patients</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div className="owl-carousel header-carousel">
                        <div className="owl-carousel-item position-relative">
                            <img className="img-fluid" src="images/hero2.jpg" alt="" />
                            <div className="owl-carousel-text">
                                <h1 className="display-1 text-white mb-0">Physiotherapy
                                </h1>
                            </div>
                        </div>
                        <div className="owl-carousel-item position-relative">
                            <img className="img-fluid" src="images/hero2.jpg" alt="" />
                            <div className="owl-carousel-text">
                                <h1 className="display-1 text-white mb-0">Occupational Therapy
</h1>
                            </div>
                        </div>
                        <div className="owl-carousel-item position-relative">
                            <img className="img-fluid" src="images/hero2.jpg" alt="" />
                            <div className="owl-carousel-text">
                                <h1 className="display-1 text-white mb-0">Child & Adolescent Psychiatry
</h1>
                            </div>
                        </div>
                        <div className="owl-carousel-item position-relative">
                            <img className="img-fluid" src="images/hero2.jpg" alt="" />
                            <div className="owl-carousel-text">
                                <h1 className="display-1 text-white mb-0">Speech Therapist
</h1>
                            </div>
                        </div>
                        <div className="owl-carousel-item position-relative">
                            <img className="img-fluid" src="images/hero2.jpg" alt="" />
                            <div className="owl-carousel-text">
                                <h1 className="display-1 text-white mb-0">Psychology
</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Slider