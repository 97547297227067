function WhyUs() {
    return (
        <div className="container-fluid overflow-hidden my-5 px-lg-0" style={{backgroundColor: "#2e7ebe"}}>
            <div className="container feature px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                        <div className="p-lg-5 ps-lg-0">
                            <h1 className="text-white mb-4">Start Your Child's Rehabilitation Journey Today
At Wldy Medical Center </h1>
                            <p className="text-white mb-4 pb-2">we are committed to helping your child reach their full potential. Contact us to learn more about our services or to schedule an appointment. Let us be your partner in fostering your child's health, well-being, and development.</p>
                            <div className="row g-4">
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                            <i className="fa fa-user-md text-primary"></i>
                                        </div>
                                        <div className="ms-4">
                                            <h5 className="text-white mb-0">Comprehensive Care</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                            <i className="fa fa-check text-primary"></i>
                                        </div>
                                        <div className="ms-4">
                                            <h5 className="text-white mb-0">Expert Professionals</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                            <i className="fa fa-comment-medical text-primary"></i>
                                        </div>
                                        <div className="ms-4">
                                            <h5 className="text-white mb-0">Family-Centered Approach</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                            <i className="fa fa-headphones text-primary"></i>
                                        </div>
                                        <div className="ms-4">
                                            <h5 className="text-white mb-0">Welcoming Environment</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '400px' }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src="/images/whyus.JPG" style={{ objectFit: 'fill' }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default WhyUs