function Specialities() {

    const featuresData = [


        {
            imgSrc: 'services/dermatology.jpg',
            alt: 'Physiotherapy',
            icon: 'FaRegSun',
            title: 'Physiotherapy',
            desc: "At Ivory Medical Center, our Dermatology and Antiaging services blend cutting-edge science with personalized care to revitalize your skin and combat the signs of aging. Our dermatologists are experts in treating a wide range of skin conditions, from acne to eczema, and are also skilled in the latest antiaging techniques, including botox, fillers, and laser therapies. We focus on enhancing your natural beauty and promoting skin health, giving you a youthful, vibrant complexion that reflects your inner vitality."
        },

        {
            imgSrc: 'services/oral.jpg',
            alt: 'Occupational Therapy',
            icon: 'FaRegSun',
            title: 'Occupational Therapy',
            desc: "Our Oral and Maxillofacial Surgery services offer comprehensive solutions for a variety of conditions affecting the mouth, teeth, jaws, and facial structures. Whether you require wisdom teeth removal, dental implants, or corrective jaw surgery, our skilled maxillofacial surgeons are equipped with the latest technology to ensure precise and effective treatment. We are committed to providing exceptional care and support throughout your surgical journey to improve your oral function, appearance, and overall quality of life."
        },

        {
            imgSrc: 'services/ortho.jpg',
            alt: 'Speech Therapist',
            icon: 'FaTeeth',
            title: 'Speech Therapy',
            desc: "The Orthodontics services at Ivory Medical Center are dedicated to creating beautiful, straight smiles for patients of all ages. Using a personalized approach, our orthodontists provide a range of treatments, including traditional braces, clear aligners, and other orthodontic appliances. We are committed to using the latest techniques and technologies to achieve optimal results, ensuring that your journey to a perfect smile is as comfortable and efficient as possible."
        },
        {
            imgSrc: 'services/Endocrinology.jpg',
            alt: 'Occupational Therapy',
            icon: 'FaTeethOpen',
            title: 'Occupational Therapy',
            desc: "Endodontics is the dental specialty concerned with the study and treatment of the dental pulp. At Ivory Medical Center, our endodontists are experts in saving teeth, specializing in procedures like root canal therapy, which is designed to treat problems within the tooth's soft core (dental pulp). Advanced techniques and technologies, such as operating microscopes and digital imaging, enable us to perform these procedures with the highest level of precision and success.",

        },
        {
            imgSrc: 'services/Endocrinology.jpg',
            alt: 'Child & Adolescent Psychiatry',
            icon: 'FaTeethOpen',
            title: 'Child & Adolescent Psychiatry',
            desc: "Endodontics is the dental specialty concerned with the study and treatment of the dental pulp. At Ivory Medical Center, our endodontists are experts in saving teeth, specializing in procedures like root canal therapy, which is designed to treat problems within the tooth's soft core (dental pulp). Advanced techniques and technologies, such as operating microscopes and digital imaging, enable us to perform these procedures with the highest level of precision and success.",

        },
        

    ];
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
               
                    <h1 style={{color: "#2e7ebe"}}>Health Care Solutions</h1>
                </div>
                <div className="row g-4">
                    {/* Service 1 */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{height: "200px"}}>
                        <div className="service-item bg-light rounded h-100 p-5">
                            <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                <i className="fa fa-heartbeat fs-4" style={{color: "#2e7ebe"}}></i>
                            </div>
                            <h4 className="mb-3" style={{color: "#2e7ebe"}}>Physiotherapy</h4>
                            {/* <p className="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</a> */}
                        </div>
                    </div>

                    {/* Service 2 */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{height: "200px"}}>
                        <div className="service-item rounded h-100 p-5" style={{backgroundColor: "#ef8bbf"}}>
                            <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                <i className="fa fa-x-ray fs-4" style={{color: "#dc0976"}}></i>
                            </div>
                            <h4 className="mb-3" style={{color: "#dc0976"}}>Occupational Therapy</h4>
                            {/* <p className="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</a> */}
                        </div>
                    </div>

                    {/* Service 3 */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{height: "200px"}}>
                        <div className="service-item bg-light rounded h-100 p-5">
                            <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                <i className="fa fa-heartbeat fs-4" style={{color: "#2e7ebe"}}></i>
                            </div>
                            <h4 className="mb-3" style={{color: "#2e7ebe"}}>Child & Adolescent Psychiatry</h4>
                            {/* <p className="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</a> */}
                        </div>
                    </div>

                    {/* Service 4 */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{height: "200px"}}>
                        <div className="service-item rounded h-100 p-5" style={{backgroundColor: "#ef8bbf"}}>
                            <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                <i className="fa fa-x-ray fs-4" style={{color: "#dc0976"}}></i>
                            </div>
                            <h4 className="mb-3" style={{color: "#dc0976"}}>Speech Therapist</h4>
                            {/* <p className="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</a> */}
                        </div>
                    </div>

                    {/* Service 5 */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{height: "200px"}}>
                        <div className="service-item bg-light rounded h-100 p-5">
                            <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                <i className="fa fa-heartbeat fs-4" style={{color: "#2e7ebe"}}></i>
                            </div>
                            <h4 className="mb-3" style={{color: "#2e7ebe"}}>Psychology</h4>
                            {/* <p className="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a className="btn" href=""><i className="fa fa-plus text-primary me-3"></i>Read More</a> */}
                        </div>
                    </div>

                    {/* Service 6 */}
                    
                </div>
            </div>
        </div>
    )
}
export default Specialities