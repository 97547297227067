function ContactUs() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-4">
                        <div className="h-100 rounded d-flex align-items-center p-5" style={{backgroundColor: "#2e7ebe"}}>
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{ width: '55px', height: '55px' }}>
                                <i className="fa fa-map-marker-alt " style={{color: "#2e7ebe"}}></i>
                            </div>
                            <div className="ms-4">
                                <p className="mb-2" style={{color: "white"}}>Address</p>
                                <h5 className="mb-0" style={{color: "white"}}>Hamdan Bin Zayed Al Awwal St - Al Jimi - Al Ameriya - Al Ain</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="h-100 rounded d-flex align-items-center p-5" style={{backgroundColor: "#2e7ebe"}}>
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{ width: '55px', height: '55px' }}>
                                <i className="fa fa-phone-alt " style={{color: "#2e7ebe"}}></i>
                            </div>
                            <div className="ms-4">
                                <p className="mb-2"  style={{color: "white"}}>Call Us Now</p>
                                <h5 className="mb-0" style={{color: "white"}}>03 766 6626
</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="h-100 rounded d-flex align-items-center p-5" style={{backgroundColor: "#2e7ebe"}}>
                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white" style={{ width: '55px', height: '55px' }}>
                                <i className="fa fa-envelope-open" style={{color: "#2e7ebe"}}></i>
                            </div>
                            <div className="ms-4">
                                <p className="mb-2" style={{color: "white"}}>Mail Us Now</p>
                                <h5 className="mb-0" style={{color: "white"}}>Info@wLdy.Ae</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style={{backgroundColor: "#dc0976"}}>
                        <div className=" rounded p-5">
                            <h1 className="mb-4" style={{color: "white"}}>Have Any Query? Please Contact Us!</h1>
                            <p className="mb-4" style={{color: "white"}}>Ready to help your child overcome challenges and reach their full potential? Contact Wldy Medical Center in Al Ain today and take the first step towards comprehensive rehabilitation in a safe, nurturing environment</p>
                            <form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: '100px' }}></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn  w-100 py-3" type="submit" style={{backgroundColor: "#2e7ebe", color: "white"}}>Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <div className="h-100" style={{ minHeight: '400px' }}>
                            <iframe
                                className="rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14551.26294140576!2d55.7267857!3d24.2482227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab3c5052c8cef%3A0x6eb543f1881c07b!2z2YXYsdmD2LIg2YjZhNiv2Yog2KfZhNi32KjZiiBXTERZIE1lZGljYWwgQ2VudGVy!5e0!3m2!1sen!2sae!4v1706855941926!5m2!1sen!2sae"
                                frameBorder="0"
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ContactUs