import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import React, { useState, useEffect } from 'react';
import Appointment from "../Appointment/Appointment";

function SpecialitiesPage() {

    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const featuresData = [


        {
            imgSrc: 'services/kids.jpg',
            alt: 'Physiotherapy',
            icon: 'FaRegSun',
            title: 'Physiotherapy',
            desc: "Mobility and physical functioning are the heartbeats of physiotherapy. Our knowledgeable physiotherapists specialize in pediatric care, employing exercises and innovative treatments that promote strength, coordination, and movement. Whether recovering from injury or managing a condition, we make movement easier for your child."
        },

    

        {
            imgSrc: 'services/SpeechTherapy.jpg',
            alt: 'Speech Therapist',
            icon: 'FaTeeth',
            title: 'Speech Therapy',
            desc: "Communication is crucial at every stage of life, especially in childhood. Our speech therapy programs address a range of speech and language disorders, ensuring your child can express themselves clearly and effectively. Utilizing the latest techniques and tools, we help bridge the gap in communication skills."
        },
        {
            imgSrc: 'services/occu.jpg',
            alt: 'Occupational Therapy',
            icon: 'FaTeethOpen',
            title: 'Occupational Therapy',
            desc: "Our experienced occupational therapists help children gain independence in all areas of their lives. From fine motor skills to sensory processing issues, our tailored approaches empower kids with the abilities they need to navigate their daily routines and improve their quality of life.",

        },
        {
            imgSrc: 'services/psyc.jpg',
            alt: 'Child & Adolescent Psychiatry',
            icon: 'FaTeethOpen',
            title: 'Child & Adolescent Psychiatry',
            desc: "When necessary, our board-certified child psychiatrists step in to manage and treat more complex behavioral and mental health issues. We provide thorough assessments and create personalized treatment plans that may include counseling, behavioral therapy, or medication management.",

        },
        {
            imgSrc: 'services/mental.jpg',
            alt: 'Psychology',
            icon: 'FaTeethOpen',
            title: 'Psychology',
            desc: "A child’s mental health is just as important as physical health. Our compassionate psychologists are attuned to the emotional and developmental needs of children. Through individualized therapy and evidence-based interventions, we support and nurture your child's mental well-being.",

        },
        

    ];
    return (
        <section className="about_section layout_padding" style={{ marginTop: '50px' }}>
            <Appointment show={show} handleClose={handleClose} />
            <div className="container">
                <div className="row">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Col sm={3}>
                            <Nav variant="none" className="flex-column">
                                {featuresData.map((speciality, index) => (
                                    <Nav.Item key={index}>
                                        <Nav.Link eventKey={index} style={{ color: "black" }}>{speciality.title}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {featuresData.map((speciality, index) => (
                                    <Tab.Pane key={index} eventKey={index}>
                                        <img src={speciality.imgSrc} alt={speciality.alt} style={{ width: "100%" }} />
                                        <h1>{speciality.title}</h1>
                                        <h2 style={{fontWeight: "lighter", fontSize: "20px", color: "black"}}>{speciality.desc}</h2>
                                        <a onClick={() => handleShow()} style={{backgroundColor: "#dc0976", borderColor: "#dc0976"}} className="btn btn-primary rounded-0 py-4 px-lg-5 d-lg-block">Make an Appointment<i className="fa fa-arrow-right ms-3"></i></a>

                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )
}
export default SpecialitiesPage