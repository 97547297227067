import React from 'react';
import Slider from '../Slider/Slider';
import About from '../About/About';
import Specialities from '../Specialities/Specialities';
function Home() {
    return (
        <div>
            <Slider />
            <About />
            <Specialities />
        </div>
    )

}
export default Home