import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home';
import Slider from './components/Slider/Slider';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';
import AboutUs from './components/AboutUs/AboutUs';
import DoctorPage from './components/DoctorPage/DoctorPage';
import SpecialitiesPage from './components/SpecialitiesPage/SpecialitiesPage';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
function App() {

  const [activeComponent, setActiveComponent] = useState('home');

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };
  return (
    <Router>
      <Navbar onComponentChange={handleComponentChange} activeComponent={activeComponent} />
      <Routes>
        <Route path="/" element={<Home onComponentChange={handleComponentChange} />} />
        <Route path="/about" element={<AboutUs onComponentChange={handleComponentChange} />} />
        <Route path="/doctors" element={<DoctorPage onComponentChange={handleComponentChange} />} />
        <Route path="/specialities" element={<SpecialitiesPage onComponentChange={handleComponentChange} />} />
        <Route path="/contact" element={<ContactUs onComponentChange={handleComponentChange} />} />
        
      </Routes>
      <Footer onComponentChange={handleComponentChange} activeComponent={activeComponent}/>
    </Router>

  );
}

export default App;
