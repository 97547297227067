import { Link } from "react-router-dom"
import React, { useState } from 'react';

function Footer({ onComponentChange, activeComponent }) {

    const handleNavItemClick = (item) => {
        onComponentChange(item,);
       
    };
    return (
        <div className="container-fluid text-light footer mt-5 pt-5 wow fadeIn" style={{ backgroundColor: "#2e7ebe" }} data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Address</h5>
                        <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Hamdan Bin Zayed Al Awwal St - Al Jimi - Al Ameriya - Al Ain</p>
                        <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>03 766 6626</p>
                        {/* <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@example.com</p> */}
                        <div className="d-flex pt-2">
                            {/* <a className="btn btn-outline-light btn-social rounded-circle" href=""><i className="fab fa-twitter"></i></a> */}
                            <a className="btn btn-outline-light btn-social rounded-circle" href="https://www.facebook.com/WLDYMC/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-light btn-social rounded-circle" href="https://www.instagram.com/wldymc/?hl=en" target="_blank"><i className="fab fa-instagram"></i></a>
                            {/* <a className="btn btn-outline-light btn-social rounded-circle" href=""><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Quick Links</h5>
                        <Link to="/" onClick={() => handleNavItemClick('home')} className="btn btn-link" >Home</Link>
                        <Link to="/about" onClick={() => handleNavItemClick('about')} className="btn btn-link" >About Us</Link>
                        <Link to="/specialities" onClick={() => handleNavItemClick('specialities')} className="btn btn-link" >Specialities</Link>
                        <Link to="/doctors" onClick={() => handleNavItemClick('doctors')} className="btn btn-link" href="">Doctors</Link>
                        <Link to="/contact" onClick={() => handleNavItemClick('contact')} className="btn btn-link" href="">Contact Us</Link>
                    </div>
                    <div className="col-lg-3 col-md-6">

                    </div>
                    <div className="col-lg-3 col-md-6">

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                     wldy medical center, All Right Reserved 2024.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Footer


