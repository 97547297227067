import About from "../About/About"
import WhyUs from "../WhyUs/WhyUs"
function AboutUs()
{
    return(
        <div>
            <WhyUs />
            <About />
            
        </div>
        
    )

}
export default AboutUs