import React, { useState, useEffect } from 'react';


function DoctorPage() {

    const [showAppointment, setShowAppointment] = useState(false);
    const [activeDoctor, setActiveDoctor] = useState(null);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    const openAppointmentModal = (doc) => {
        setActiveDoctor(doc)
        handleShowAppointment()
    };
    const allDoctors = [
        { id: 11, name: 'Najwa Ismail', image: '/doctors/najwa.jpg', speciality: 'Physiotherapist', languages: ['Arabic', 'English'] },
        { id: 9, name: 'Hala Mousa', image: '/doctors/hala.jpg', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
        { id: 7, name: 'Abeer Yousef', image: '/doctors/abeer.jpg', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
        { id: 1, name: 'Dr. Muhammad Rami Arjah', image: '/doctors/user.png', speciality: 'Consultant Child & Adolescent Psychiatry', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Iman Mahasneh', image: '/doctors/user.png', speciality: 'Speech Therapist', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Mohammed Al Smadi', image: '/doctors/user.png', speciality: 'Speech Therapist', languages: ['Arabic', 'English'] },
        { id: 4, name: 'Kunnampallil Gego', image: '/doctors/user.png', speciality: 'Speech Therapist', languages: ['English', 'Arabic'] },
        { id: 5, name: 'Lana Al Natour', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['English', 'Arabic'] },
        { id: 6, name: 'Adnan Aburayya', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['English', 'Arabic'] },
        { id: 8, name: 'Raed Alklakil', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Hiba Mohammad', image: '/doctors/user.png', speciality: 'Occupational Therapist', languages: ['Arabic', 'English'] },
    ];

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));


    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language)));
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };
    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };


    return (

        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                    <h1>Our Experienced Doctors</h1>
                </div>
                <div className='row' style={{marginBottom: "20px"}}>
                    <div className="col-12 col-sm-6">
                        <select onChange={handleSpecialityChange} className="form-select border-0" style={{ height: '55px' }}>
                            <option value="">All Specialities</option>
                            {specialities.map((speciality, index) => (
                                <option key={index} value={speciality}>
                                    {speciality}
                                </option>
                            ))}

                        </select>
                    </div>
                </div>
                <div className="row g-4">
                    {filteredDoctors.map((doctor, index) => (
                        <div key={index} className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay={`${index * 0.2}s`}>
                            <div className="team-item position-relative rounded overflow-hidden">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={doctor.image} alt={`Doctor ${doctor.name}`} style={{ height: "450px", objectFit: "contain" }} />
                                </div>
                                <div className="team-text bg-light text-center p-4">
                                    <h5>{doctor.name}</h5>
                                    <p className="text-primary">{doctor.speciality}</p>
                                    {/* <div className="team-social text-center">
                                        <a className="btn btn-square" href=""><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-square" href=""><i className="fab fa-twitter"></i></a>
                                        <a className="btn btn-square" href=""><i className="fab fa-instagram"></i></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
export default DoctorPage