import { Link } from "react-router-dom"
import React, { useState } from 'react';
import Appointment from "../Appointment/Appointment";
function Navbar({ onComponentChange }) {

    const [activeNavItem, setActiveNavItem] = useState('home');

    const handleNavItemClick = (item) => {
        setActiveNavItem(item);
        onComponentChange(item,);
       
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <Appointment show={show} handleClose={handleClose} />
            <div className="container-fluid bg-light p-0 wow fadeIn" data-wow-delay="0.1s" >
                <div className="row gx-0 d-none d-lg-flex" style={{backgroundColor: "#dc0976"}}>
                    <div className="col-lg-7 px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <small style={{color: "white"}} className="fa fa-map-marker-alt me-2"></small>
                            <small style={{color: "white"}}>Hamdan Bin Zayed Al Awwal St - Al Jimi - Al Ameriya - Al Ain</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <small style={{color: "white"}} className="far fa-clock me-2"></small>
                            <small style={{color: "white"}}>Mon - Sun : 10.00 AM - 09.00 PM</small>
                        </div>
                    </div>
                    <div className="col-lg-5 px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <small style={{color: "white"}} className="fa fa-phone-alt me-2"></small>
                            <small style={{color: "white"}}>03 766 6626</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center"> 
                            <a className="btn btn-sm-square rounded-circle bg-white text-primary me-1" href="https://www.facebook.com/WLDYMC/" target="_blank"><i style={{color: "#dc0976"}} className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-sm-square rounded-circle bg-white text-primary me-0" href="https://www.instagram.com/wldymc/?hl=en" target="_blank"><i style={{color: "#dc0976"}} className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 wow fadeIn" data-wow-delay="0.1s">
                <Link to="/" onClick={() => handleNavItemClick('home')} className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                   <img src="logo.png" style={{width: "100px"}}/>
                </Link>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link style={{ color: activeNavItem === "home" ? "#dc0976" : "#2e7ebe" }} to="/" onClick={() => handleNavItemClick('home')} className="nav-item nav-link">Home</Link>
                        <Link style={{ color: activeNavItem === "about" ? "#dc0976" : "#2e7ebe" }} to="/about" onClick={() => handleNavItemClick('about')} className="nav-item nav-link">About</Link>
                        <Link style={{ color: activeNavItem === "specialities" ? "#dc0976" : "#2e7ebe" }} to="/specialities" onClick={() => handleNavItemClick('specialities')} className="nav-item nav-link">Specialities</Link>
                        {/* <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" style={{ color: "#2e7ebe" }}>Pages</a>
                            <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                                <a href="feature.html" className="dropdown-item">Feature</a>
                                <a href="team.html" className="dropdown-item">Our Doctor</a>
                                <a href="appointment.html" className="dropdown-item">Appointment</a>
                                <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                                <a href="404.html" className="dropdown-item">404 Page</a>
                            </div>
                        </div> */}
                        <Link style={{ color: activeNavItem === "doctors" ? "#dc0976" : "#2e7ebe" }} to="/doctors" onClick={() => handleNavItemClick('doctors')} className="nav-item nav-link">Doctors</Link>
                        <Link style={{ color: activeNavItem === "contact" ? "#dc0976" : "#2e7ebe" }} to="/contact" onClick={() => handleNavItemClick('contact')} className="nav-item nav-link" >Contact Us</Link>

                    </div>
                    <a onClick={() => handleShow()} style={{backgroundColor: "#dc0976", borderColor: "#dc0976"}} className="btn btn-primary rounded-0 py-4 px-lg-5 d-lg-block">Make an Appointment<i className="fa fa-arrow-right ms-3"></i></a>
                </div>
            </nav>
        </div>
    )

}
export default Navbar