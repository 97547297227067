function About() {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    {/* Column 1 (Left Column) */}
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="d-flex flex-column">
                            <img className="img-fluid rounded w-50 align-self-end" src="images/pic2.JPG" alt="" />
                            <img className="img-fluid rounded w-75  pt-3 pe-3" src="images/pic1.JPG" alt="" style={{ marginTop: '-25%' }} />
                        </div>
                    </div>

                    {/* Column 2 (Right Column) */}
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <h1 className="mb-4" style={{color: "#dc0976"}}>Why Choose Wldy Medical Center?</h1>
                        <p>At Wldy Medical Center, we unite leading professionals, caring support, and a child-friendly environment to facilitate recovery and growth. Our Al Ain location is not just a place for treatment; it's a space where children and their families find hope and a path forward.</p>
                        <p className="mb-4">Embark on a journey to wellness with Wldy Medical Center, where every child's success is our greatest reward.</p>
                        <p ><i className="far fa-check-circle me-3" style={{color: "#dc0976"}}></i>Our multidisciplinary team offers a wide range of services under one roof, ensuring comprehensive care tailored to your child's needs.</p>
                        <p><i className="far fa-check-circle me-3" style={{color: "#dc0976"}}></i>Our specialists are highly qualified and experienced in pediatric rehabilitation, providing the highest standard of care.</p>
                        <p><i className="far fa-check-circle me-3" style={{color: "#dc0976"}}></i>We believe in involving families in the care process, offering support and guidance every step of the way.</p>
                        <p><i className="far fa-check-circle me-3" style={{color: "#dc0976"}}></i>Our child-friendly facilities are designed to make children feel comfortable and safe, promoting a positive healing experience.</p>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default About